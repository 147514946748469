<template>
  <div class="partner-region">
    <div class="title font-md">
      <span>지역별 현황 </span>
    </div>
    <div class="desc font-sm">해당 지역을 클릭하시면 지역별 참여현황을 보실 수 있습니다.</div>
    <div class="map">
      <div class="board">
        <div class="row">
          <div class="col-lg-6 text-center">
            <div id="mapArea" class="map-area"></div>
          </div>
          <div class="col-lg-6">
            <table class="table font-sm">
              <thead>
              <tr>
                <th>프로젝트 지역</th>
                <th>개수</th>
                <th>투자자</th>
                <th>투자 금액</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(d, idx) in state.mapData" :key="idx" class="pointer" :class="{fix: state.regionCode.fix === d.properties.code, active: state.regionCode.over === d.properties.code}"
                  @mouseenter="setRegionCode(d.properties.code)"
                  @mouseleave="setRegionCode()"
                  @click="setRegionCode(d.properties.code, true)"
                  v-show="d.properties.projectCount > 0">
                <td>{{ d.properties.name }}</td>
                <td>{{ $lib.getNumberFormat(d.properties.projectCount) }}</td>
                <td>{{ $lib.getNumberFormat(d.properties.investorCount) }}</td>
                <td>{{ $lib.getNumberFormat(d.properties.totalAmount) }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="cards" v-if="state.projects.length">
          <ul class="tight clearfix" ref="cardsUlRef">
            <li v-for="(p, idx) in state.projects" :key="idx">
              <Card
                  :projectSeq="Number(p.projectSeq)"
                  :projectType="p.projectType"
                  :link="p.url"
                  :thumbFilePath="p.filePath"
                  :amount="Number(p.expenseAmt)"
                  :projectName="p.projectName"
                  :progressOrder="Number(p.progressOrder)"
                  :simpleText="p.simpleText"
                  :projectCate="p.projectCateName"
                  :count="Number(p.investorCount)"
                  :percent="Number(p.per)"
                  :builderName="p.builderName"
                  :builderImageUrl="p.builderFilePath"
                  :builderSeq="p.builderSeq ? p.builderSeq : p.openId"
                  :successFailName="p.successFailName"
                  :interest="false"
                  :mainImagePosition="p.mainImagePosition"
                  v-if="p.projectType === 'loan'"
              />
              <Card
                  :projectSeq="p.projectSeq"
                  :projectType="p.projectType"
                  :link="`/${p.projectType}/${p.projectSeq}`"
                  :thumbFilePath="p.thumbFilePath"
                  :amount="p.progressOrder === 1 ? p.projectType === 'reward' ? p.expenseAmt : p.expenseAmt : p.expenseAmt"
                  :projectName="p.projectName"
                  :progressOrder="p.progressOrder"
                  :simpleText="p.simpleText"
                  :projectCate="p.projectCateName"
                  :fundingType="p.fundingType"
                  :count="p.investorCount"
                  :percent="p.investRate"
                  :dday="Number(p.dday)"
                  :builderName="p.builderName"
                  :builderImageUrl="p.builderImageUrl"
                  :builderSeq="p.builderSeq ? p.builderSeq : p.openId"
                  :successFailName="p.successFailName"
                  :interest="true"
                  :mainImagePosition="p.mainImagePosition"
                  v-else
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, nextTick, onMounted, reactive, ref, set} from "@vue/composition-api";
import http from "@/scripts/http";
import lib from "@/scripts/lib";
import router from "@/scripts/router";
import store from "@/scripts/store";
import Card from "@/components/Card";
import mixin from "@/scripts/mixin";
import {httpError} from "@/scripts/httpError";

function Component(initialize) {
  this.name = "modalPartnerRegion";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {Card},
  setup() {
    const component = new Component(() => {
      store.commit("tuneModal", {component, size: "lg"});
    });

    const state = reactive({
      contestSeq: "",
      regionCode: {
        fix: "",
        over: ""
      },
      mapData: [],
      projects: []
    });

    const cardsUlRef = ref();

    const setRegionCode = (regionCode, click) => {
      if (click) {
        state.regionCode.fix = regionCode ? regionCode : "";
      } else {
        state.regionCode.over = regionCode ? regionCode : "";
      }

      const $regions = document.querySelectorAll(".region.selectable");

      if (regionCode) {
        const classValue = click ? "fix" : "over";

        for (let i = 0; i < $regions.length; i += 1) {
          if (!$regions[i]?.classList?.contains) { // IE11 SVG classList 미지원
            continue;
          }

          if (!$regions[i].classList.contains("region-" + regionCode)) {
            $regions[i].classList.remove(classValue);
          } else if (!$regions[i].classList.contains(classValue)) {
            $regions[i].classList.add(classValue);
          }
        }

        if (click) {
          const region = state.mapData.find(d => d.properties.code === regionCode);
          state.projects = region.properties.projects;

          nextTick(() => {
            const $modal = document.querySelector(".modals > .modal");
            const top = $modal.querySelector(".partner-region").getBoundingClientRect().top;
            lib.scrollSmoothly($modal, cardsUlRef.value.offsetTop + $modal.scrollTop + top);
            lib.setBodyScroll();
          });
        }
      } else {
        for (let i = 0; i < $regions.length; i += 1) {
          $regions[i].classList.remove("over");
        }
      }
    };

    onMounted(() => {
      const scripts = {
        d3: {src: "/assets/lib/d3.min.js"},
        topojson: {src: "/assets/lib/topojson.min.js"},
        lodash: {src: "/assets/lib/lodash.min.js"},
      };

      let scriptLoadCnt = 0;

      const run = () => {
        const modalParams = store.getters.modalParams(component);
        const regionId = modalParams?.detail?.dashboardRegionCode ? modalParams.detail.dashboardRegionCode : "00000";
        state.contestSeq = router.app.$route.params.contestSeq;

        const boardControl = new BoardControl({
          mapWrapperId: "#mapArea",
          projectListId: "#project-area",
          regionTableBodyId: "#areaList",
          regionId: regionId
        });

        boardControl.load();

        if (modalParams?.detail?.externalConnectedCode === "BPLUS") {
          boardControl.loadBeProject();
        }
      };

      for (let i in scripts) {
        store.dispatch("appendScript", {
          src: scripts[i].src,
          onEveryLoad() {
            ++scriptLoadCnt === Object.keys(scripts).length && run();
          }
        });
      }

      function BoardControl(options) {
        const d3 = window.d3;
        const topojson = window.topojson;
        const $ = window.$;
        const _ = window._;

        const svgWidth = 280;
        const svgHeight = 625;
        const getRegionCode = ($element) => {
          const tokens = $element.attr("class").split(" ");
          let i, test, code;

          if (!tokens.length) {
            return;
          }

          for (i = 0; i < tokens.length; i++) {
            test = /^region-[0-9]+$/.exec(tokens[i]);

            if (test && test.length) {
              break;
            }
          }

          if (!test || !test.length) {
            return;
          }

          code = /[\d]+/.exec(test);
          return code.toString();
        };

        this.mapWrapperId = options.mapWrapperId;
        this.projectListId = options.projectListId;
        this.regionTableBodyId = options.regionTableBodyId;
        this.regionId = options.regionId;

        this.initMap = function (after) {
          let self = this;

          if (!self.mapWrapperId || !self.regionId) {
            return;
          }

          let mapFilePath = self.getMapFilePath(self.regionId);
          self.svg = d3.select(self.mapWrapperId).append("svg")
          .attr("width", svgWidth)
          .attr("height", svgHeight);

          self.mapGroup = self.svg.append("g").attr("id", "map-group");

          d3.json(mapFilePath, function (error, data) {
            let features = topojson.feature(data, data.objects["geo"]).features;

            if (Array.isArray(features)) {
              features.sort(function (a, b) {
                let c = parseInt(a.properties.code) || 0;
                let d = parseInt(b.properties.code) || 0;
                return c - d;
              });
            }

            state.mapData = features;
            self.mapCenter = data.transform.translate;
            self.mapScale = data.transform.dashboardScale;
            self.mapTranslate = data.transform.dashboardTranslate;
            self.svgHeight = data.transform.svgHeight;
            self.syncData();
            typeof after === "function" && after.call(self);
          });
        };

        this.getMapFilePath = function (regionId) {
          const path = "/assets/json/map/";

          switch (regionId) {
            case "00000":
              return path + "koreaProvinces.json";

            case "01000":
              return path + "koreaSeoul.json";

            case "10000":
              return path + "koreaGyeonggi.json";

            case "21000":
              return path + "koreaIncheon.json";

            case "24000":
              return path + "koreaGangwon.json";

            case "27000":
              return path + "koreaChungBuk.json";

            case "30000":
              return path + "koreaSejong.json";

            case "31000":
              return path + "koreaChungNam.json";

            case "34000":
              return path + "koreaDaejeon.json";

            case "36000":
              return path + "koreaGyeongBuk.json";

            case "41000":
              return path + "koreaDaegu.json";

            case "44000":
              return path + "koreaUlsan.json";

            case "46000":
              return path + "koreaBusan.json";

            case "50000":
              return path + "koreaGyeongNam.json";

            case "54000":
              return path + "koreaJeolBuk.json";

            case "57000":
              return path + "koreaJeolNam.json";

            case "61000":
              return path + "koreaGwangju.json";

            case "63000":
              return path + "koreaJeju.json";
          }

          return "";
        };

        this.renderMap = function () {
          let self = this;

          if (!self.svg || !self.mapGroup || !state.mapData) {
            return;
          }

          self.syncData();
          self.mapGroup.selectAll("*").remove();
          self.svg.attr("height", self.svgHeight || svgHeight);

          let projection = d3.geo.mercator()
          .center(self.mapCenter)
          .scale(self.mapScale)
          .translate([self.mapTranslate.x, self.mapTranslate.y]);

          let path = d3.geo.path().projection(projection);

          self.mapGroup.selectAll("path")
          .data(state.mapData)
          .enter().append("path")
          .attr("class", function (d) {
            if (d.properties.projectCount > 0) {
              return "region selectable region-" + d.properties.code;
            } else {
              return "region region-" + d.properties.code;
            }
          })
          .attr("d", path);

          let selectableRegions = self.mapGroup.selectAll(".region.selectable");

          selectableRegions.on("mouseover", function () {
            setRegionCode(getRegionCode($(this)));
          });

          selectableRegions.on("mouseout", function () {
            setRegionCode();
          });

          selectableRegions.on("click", function () {
            setRegionCode(getRegionCode($(this)), true);
          });

          $(self.mapWrapperId).addClass("animated fadeIn");
        };

        this.load = function () {
          let self = this;
          const url = `/api/partnerships/${state.contestSeq}/region/projects`;

          http.get(url).then(({data}) => {
            self.parseData(data.body);
            self.renderMap();
          }).catch(httpError(() => {
            setTimeout(self.load, 1000);
          }));
        };

        this.getRegionByZip = function (zip) {
          let zipNumber = parseInt(zip);

          if (!zipNumber) {
            return;
          }

          return _.findLast(state.mapData, function (e) {
            let code = parseInt(e.properties.code);
            return code < zipNumber;
          });
        };

        this.parseData = function (projectData) {
          if (!projectData || !Array.isArray(projectData)) {
            return;
          }

          this.projectData = projectData;
        };

        this.syncData = function () {
          let self = this;

          if (!state.mapData || !self.projectData) {
            return;
          }

          for (let i in state.mapData) {
            set(state.mapData[i].properties, "projectCount", 0);
            set(state.mapData[i].properties, "investorCount", 0);
            set(state.mapData[i].properties, "totalAmount", 0);
            set(state.mapData[i].properties, "projects", []);
          }

          self.projectData.forEach(function (element) {
            let region = self.getRegionByZip(element.zip);

            if (!region) {
              return;
            }

            region.properties.projectCount += 1;
            region.properties.investorCount += element.investorCount;
            region.properties.totalAmount += element.expenseAmt;
            region.properties.projects.push(element);
          });

          self.syncBenefitplusData();

          state.mapData.forEach(function (element) {
            element.properties.projects.sort(function (a, b) {
              return b.expenseAmt - a.expenseAmt;
            });
          });
        };

        // 비플러스 대출형 프로젝트 정보 조회 및 업데이트 / @desc 2018 사회적경제기업 크라우드펀딩대회 한정 기능(CONTEST_SEQ = 502)
        this.loadBeProject = function () {
          let self = this;
          let url = `/api/partnerships/${state.contestSeq}/region/loan-projects`;

          http.get(url).then(({data}) => {
            self.loanProject = data.body;
            self.renderMap();
          }).catch(httpError((err) => {
            err?.response?.status !== 401 && setTimeout(self.loadBeProject, 1000);
          }));
        };

        // 비플러스 대출형 프로젝트 정보를 지역 정보와 동기화
        this.syncBenefitplusData = function () {
          let self = this;

          if (!Array.isArray(self.loanProject)) {
            return;
          }

          self.loanProject.forEach(function (element) {
            let region = self.getRegionByZip(element.postCode);

            if (!region) {
              return;
            }

            region.properties.projectCount += 1;
            region.properties.investorCount += window.Number(element.investorCount);
            region.properties.totalAmount += window.Number(element.expenseAmt);
            region.properties.projects.push(element);
          });
        };

        this.initData = function () {
          this.projects = [];
          this.regions = [];
        };

        this.initData();

        this.initMap(function () {
          this.renderMap();
        });
      }
    });

    return {component, state, cardsUlRef, setRegionCode};
  }
});
</script>

<style lang="scss">
.partner-region {
  background: #fff;
  padding: $px25;

  .title {
    margin-bottom: $px9;
  }

  .desc {
    margin-bottom: $px25;
    color: #666;
  }

  .board {
    path {
      stroke: #bbb;
      fill: #fff;

      &.selectable {
        cursor: pointer;
        fill: #e5e5e5;
      }

      &.over {
        fill: #aaa;
      }

      &.fix {
        fill: $colorPoint;
      }
    }

    table {
      tr {
        th {
          background: $colorBackground;
          border-bottom: 0;
        }

        &.active {
          background: $colorBackground;
        }

        &.fix {
          td {
            font-weight: 500;
            color: $colorPoint;
          }
        }
      }
    }

    .cards {
      margin-top: $px15;
      padding-top: $px15;
      border-top: $px1 solid #eee;

      ul {
        margin: 0 $px-15;

        li {
          float: left;
          width: calc(100% / 3 - 0.1px);
          padding: $px15 $px15 $px16 $px15;
        }
      }
    }

    @media(max-width: 991px) {
      .cards ul li {
        width: 100%;
      }
    }
  }
}
</style>